import React from 'react';
import styled from 'styled-components';
import MailChimp from '../components/MailChimp';




const WhiteBook = () => {
    
    return (
        <Container>
            <MailChimp />
        </Container>
    )
}

export default WhiteBook


const Container = styled.div`
display : flex;
justify-content : center;
align-items : center;
height : 100%;
width : 100%;
`;