import React, { useState } from 'react';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import styled from 'styled-components';

import Image from './HeaderLogo';
import Header from './header';
import { Submit } from './LinkButton';
import { Link } from 'gatsby';



const CustomForm = ({ status, message, onValidated }) => {
    let email, firstName, lastName, phone, company;
    const submit = () =>
        email &&
        firstName &&
        lastName &&
        phone &&
        company &&
        email.value.indexOf("@") > -1 &&
        onValidated({
            EMAIL: email.value,
            FNAME: firstName.value,
            MMERGE2: lastName.value,
            PHONE: phone.value,
            MMERGE6: company.value
        });

    const handleKeyPress = event => {
        if (event.key == 'Enter') {
            submit();
        }
    }


    return (
        <Container>
            <Header />
            {status === "success" ? (
                <FormContainer>
                    <ThanksMessage style={{marginBottom : '60px'}} >
                    Voici le lien pour télécharger notre livre blanc :
                    </ThanksMessage>
                    <ThanksMessage style={{backgroundColor : 'white'}} >
                        <a href='https://www.tmm-agency.fr/'>https://www.tmm-agency.fr/</a>
                    </ThanksMessage>
                    <ThanksMessage>
                        Merci !
                    </ThanksMessage>
                </FormContainer>)
                :
                (
                    <FormContainer>
                        <Title>
                            Pour découvrir notre livre blanc sur le marketing du « Care », merci de remplir les informations suivantes. <br/><br/>
                            Vous recevrez un lien de téléchargement vers notre contenu. <br/><br/>
                            Bonne lecture ! 
                        </Title>
                        {status === "error" && (
                            <div
                                style={{ color: "red" }}
                                dangerouslySetInnerHTML={{ __html: message }}
                            />
                        )}
                        <Label>
                            Prénom
                        </Label>
                        <Input
                            style={{ fontSize: "2em", padding: 5 }}
                            ref={node => (firstName = node)}
                            type="text"
                            placeholder="Prénom"
                            onKeyPress={handleKeyPress}
                        />
                        <Label>
                            Nom
                        </Label>
                        <Input
                            style={{ fontSize: "2em", padding: 5 }}
                            ref={node => (lastName = node)}
                            type="text"
                            placeholder="Nom"
                            onKeyPress={handleKeyPress}
                        />
                        <Label>
                            Email
                        </Label>
                        <Input
                            style={{ fontSize: "2em", padding: 5 }}
                            ref={node => (email = node)}
                            type="email"
                            placeholder="Email"
                            required={true}
                            onKeyPress={handleKeyPress}
                        />
                        <Label>
                            Téléphone
                        </Label>
                        <Input
                            style={{ fontSize: "2em", padding: 5 }}
                            ref={node => (phone = node)}
                            type="tel"
                            placeholder="Téléphone"
                            onKeyPress={handleKeyPress}
                        />
                        <Label>
                            Société
                        </Label>
                        <Input
                            style={{ fontSize: "2em", padding: 5}}
                            ref={node => (company = node)}
                            type="text"
                            placeholder="Société"
                            onKeyPress={handleKeyPress}
                        />
                        <Submit style={{ fontSize: "2em", padding: 5, zIndex : 1, marginTop : '5rem' }} onClick={submit}>
                            Envoyer
                        </Submit>
                        {status === "sending" && <Label style={{ color: "blue" }}>Sending...</Label>}
                    </FormContainer>
                )}
        </Container>
    );
};



const MailChimp = () => {
    const url = "https://agency.us20.list-manage.com/subscribe/post?u=d7eee7ccbdd03df7ae60a53e2&amp;id=46753207df";

    return (
        <MailchimpSubscribe
            url={url}
            render={({ subscribe, status, message }) => (
                <CustomForm
                    status={status}
                    message={message}
                    onValidated={formData => subscribe(formData)}
                />
            )}
        />
    )
}

export default MailChimp;


const Container = styled.div`
    width : 100%;
    height : 100%;
    display : flex;
    justify-content : center;
    align-items : center;
    flex-direction : column;
    background-color : transparent;
    margin-top : 1rem;
`;

const FormContainer = styled.div`
    position : relative;
    width : 100%;
    height : 100%;
    display : flex;
    justify-content : center;
    align-items : center;
    flex-direction : column;
    margin-top : 5rem;
    &::before {
        content : "";
        background-image : url("../../static/home_image-09032994bf6b596650b4e976ef378b47.jpg");
        background-size : cover;
        background-position : center;
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        opacity: 0.4;
    }
`;

const Input = styled.input`
position: relative;
    margin-bottom : 2rem;
    width : 20%;
`;

const Title = styled.div`
    position: relative;
    font-size : 4rem;
    font-family : TellMeMore;
    font-weight : bold;
    //background-color : rgb(254, 202, 23);
    background-color : transparent;
    margin-top : 10rem;
    width : 80%;
    text-align : center;
`;

const Label = styled.div`
    position: relative;
    font-size : 2rem;
    font-weight : bold;
    font-family : TellMeMore;
`;

const ThanksMessage = styled.div`
    position: relative;
    font-size : 4rem;
    font-weight : bold;
    font-family : TellMeMore;
    background-color : rgb(254, 202, 23);
    margin-bottom : 60px;
`;